//#region TIPO_ETAPA

export const TIPO_ETAPA_ASSINATURA = 'ASSINATURA';
export const TIPO_ETAPA_DADOS_AGREGADO = 'DADOS_AGREGADO';
export const TIPO_ETAPA_DADOS_CNH = 'DADOS_CNH';
export const TIPO_ETAPA_DADOS_ENDERECO = 'DADOS_ENDERECO';
export const TIPO_ETAPA_DADOS_IMPLEMENTO = 'DADOS_IMPLEMENTO';
export const TIPO_ETAPA_DADOS_PESSOAIS = 'DADOS_PESSOAIS';
export const TIPO_ETAPA_DADOS_VEICULO = 'DADOS_VEICULO';
export const TIPO_ETAPA_FOTO = 'FOTO';
export const TIPO_ETAPA_TERMO = 'TERMO';
export const TIPO_ETAPA_VIDEO = 'VIDEO';
export const TIPO_ETAPA_CONTRATO = 'CONTRATO';

//#endregion

//#region Vídeo e Foto

export const MEDIA_STREAM_CONSTRAINTS_PHOTO: MediaStreamConstraints = {
  audio: false,
  video: {
    width: { ideal: 1600 },
    height: { ideal: 900 },
    facingMode: 'environment',
  },
};

export const MEDIA_STREAM_CONSTRAINTS_VIDEO: MediaStreamConstraints = {
  audio: true,
  video: {
    width: { ideal: 1280 },
    height: { ideal: 720 },
    facingMode: 'environment',
  },
};

export const MAX_SECONDS_VIDEO = 180;

//#endregion

//#region Contrato

export const TITULO_POR_TIPO_CONTRATO = [
  { tipoContrato: 'Vistoria', tituloContrato: 'Termo de Vistoria' },
  {
    tipoContrato: 'Proposta de Filiação',
    tituloContrato: 'Termo de Contratação',
  },
  {
    tipoContrato: 'Termo de Rastreador',
    tituloContrato: 'Termo de Rastreador',
  },
];

//#endregion

//#region Id Modelo Fotos

export const ID_MODELO_FOTO_ASSINATURA = -1;
export const NOME_MODELO_FOTO_ASSINATURA = 'Assinatura';

export const ID_MODELO_VIDEO_ADICIONAL = 998;
export const NOME_MODELO_VIDEO_ADICIONAL = 'Vídeo Adicional';

export const ID_MODELO_FOTO_ADICIONAL = 999;
export const NOME_MODELO_FOTO_ADICIONAL = 'Foto Adicional';

export const ID_MODELO_VIDEO = [20, 21, 60, 66, ID_MODELO_VIDEO_ADICIONAL];

//#endregion

//#region Diversos

export const TIPO_FOTO_DOCUMENTACAO = 'Documentação';

export const TYPE_MINE_PDF = 'application/pdf';

export const URL_S3_FOTOS_PADRAO =
  'https://s3.amazonaws.com/bboneapp/imgsPadrao';

export const PESSOA_FISICA = 'Pessoa Física';
export const PESSOA_JURIDICA = 'Pessoa Jurídica';

//#endregion

//#region Proposta

export const STATUS_PROPOSTA_EM_ABERTO = 1;
export const STATUS_PROPOSTA_RECUSADA = 2;
export const STATUS_PROPOSTA_AGUARDANDO_APROVACAO = 3;
export const STATUS_PROPOSTA_FINALIZADA = 4;
export const STATUS_PROPOSTA_PENDENTE = 5;
export const STATUS_PROPOSTA_EM_PROCESSO_VISTORIA = 6;
export const STATUS_PROPOSTA_EM_PRE_ANALISE = 7;

export const STATUS_PERMITE_EDICAO_DADOS = [
  STATUS_PROPOSTA_EM_ABERTO,
  STATUS_PROPOSTA_EM_PRE_ANALISE,
  STATUS_PROPOSTA_EM_PROCESSO_VISTORIA,
];

export const TIPO_PROPOSTA_SUBSTITUICAO = 'Substituição';

//#endregion

//#region Lista Diversos

export const LIST_TIPO_CARTEIRA = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'AB',
  'AC',
  'AD',
  'AE',
];

export const LIST_ESTADO_PNEUS = ['Ruim', 'Regular', 'Bom', 'Novo'];
export const LIST_TIPO_CAMBIO = ['Automático', 'Manual'];

//#endregion

//#region Rotas

export const ROTA_ASSINATURA = 'assinatura';
export const ROTA_CONFIRMAR_PENDENCIA = 'confirmar-pendencia';
export const ROTA_ENVIO_VISTORIA = 'envio-vistoria';
export const ROTA_FOTO = 'foto';
export const ROTA_HOME = 'home';
export const ROTA_PERMISSOES = 'permissoes';
export const ROTA_RESULTADO = 'resultado';
export const ROTA_RESUMO = 'resumo';
export const ROTA_VERIFICAR = 'verificar';
export const ROTA_VIDEO = 'video';
export const ROTA_VISTORIA = 'vistoria';

//#endregion

//#region Tipo Veículos

export const TIPO_VEICULO_MOTO = 2;

//#endregion
