export function isMobileDevice() {
  const mobileKeywords = [
    'Android',
    'webOS',
    'iPhone',
    'iPad',
    'BlackBerry',
    'IEMobile',
    'Opera Mini',
    'Windows Phone',
  ];

  const isMobileAgent = mobileKeywords.some((keyword) =>
    navigator.userAgent.includes(keyword),
  );

  const MOBILE_SCREEN_WIDTH = 768;
  const isMobileScreen = window.innerWidth <= MOBILE_SCREEN_WIDTH;

  const hasTouchSupport = navigator.maxTouchPoints > 0;

  const isMobileDeviceDetected =
    isMobileAgent || isMobileScreen || hasTouchSupport;

  const isAccessDesktopAllowed = import.meta.env.VITE_ACCESS_DESKTOP || false;

  const isMobileDevice = isAccessDesktopAllowed || isMobileDeviceDetected;

  return isMobileDevice;
}
